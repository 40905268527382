import * as React from "react"
import { useState, useLayoutEffect, useRef } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/css/BlogDetail.scss"
import { Link } from "gatsby"
import { AiFillLinkedin } from "react-icons/ai"
import { FaTwitter } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FiArrowLeft } from "react-icons/fi"

import { BlogData } from "../components/blogDetails"

const BlogOne = () => {
  // Copy blog data to a state variable
  const [blogList, setBlogList] = React.useState([])

  React.useLayoutEffect(() => {
    setBlogList([...BlogData])
  }, [])

  const ref = useRef(null);

console.log(ref?.current?.innerText, "ref")

  // Specify the id you want to find
  const targetId = 1

  // Use the find method to get the data for the item with the matching id
  const targetItem = BlogData.find(item => item.id === targetId)

  const wordsPerMinute = 120


  if (targetItem) {
    return (
      <Layout>
        <Seo title="EdgeQ | EdgeQ Wins Multiple 2023 Global Mobile (GLOMO)" />

        <section className="News-detail blog-detail" ref={ref}>
          <Container>
            <Row>
              <div className="detail-head">
                <Link to="/blog" className="back-btn">
                  <FiArrowLeft /> Blog Page
                </Link>

                <h2>{targetItem.blogTitle}</h2>
              </div>

              <div className="detail-para">
                <p className="text-left">
                  <i>{targetItem.blogDetails}</i>
                </p>

                <p className="text-left ">
                  
                    by <a href="https://www.linkedin.com/" target="_blank">Sriram Rajagopal</a> | Head of Systems & Firmware
                  
                </p>

                <div className="ajit-social">
                  <ul className="social-icons">
                    <li>
                      <a
                        aria-label="Save"
                        href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiFillLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        aria-label="Save"
                        href="https://twitter.com/edgeq_inc?lang=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        aria-label="Save"
                        href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="blog_img_one">
                  <img src={targetItem.imgURL} alt={`img-${targetItem.id}`} />
                </div>

                <p className="text-left">
                  <i>
                    Lorem ipsum dolor sit consectetur Ut libero urna tellus
                    donec auctor.
                  </i>
                </p>

                <div className="date_time">
                  <h5>{targetItem.blogDate}</h5>
                  <h6>
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 0C11.8869 0 14.1761 0.948211 15.864 2.63604C17.5518 4.32387 18.5 6.61305 18.5 9C18.5 11.3869 17.5518 13.6761 15.864 15.364C14.1761 17.0518 11.8869 18 9.5 18C7.11305 18 4.82387 17.0518 3.13604 15.364C1.44821 13.6761 0.5 11.3869 0.5 9C0.5 6.61305 1.44821 4.32387 3.13604 2.63604C4.82387 0.948211 7.11305 0 9.5 0ZM9.5 0.947368C7.36431 0.947368 5.31609 1.79577 3.80593 3.30593C2.29577 4.81609 1.44737 6.86431 1.44737 9C1.44737 11.1357 2.29577 13.1839 3.80593 14.6941C5.31609 16.2042 7.36431 17.0526 9.5 17.0526C10.5575 17.0526 11.6046 16.8443 12.5816 16.4397C13.5586 16.035 14.4463 15.4418 15.1941 14.6941C15.9418 13.9463 16.535 13.0586 16.9397 12.0816C17.3443 11.1046 17.5526 10.0575 17.5526 9C17.5526 6.86431 16.7042 4.81609 15.1941 3.30593C13.6839 1.79577 11.6357 0.947368 9.5 0.947368ZM9.02632 3.78947H9.97368V8.92421L14.4263 11.4916L13.9526 12.3158L9.02632 9.47368V3.78947Z"
                        fill="#6A6A6A"
                      />
                    </svg>
                    {Math.ceil(
                      ref?.current?.innerText?.split(" ").length / wordsPerMinute
                    )}{" "}
                    min
                    {Math.ceil(
                      ref?.current?.innerText?.split(" ").length / wordsPerMinute
                    ) !== 1
                      ? "s"
                      : ""}

                  </h6>
                </div>

           

                <p className="text-left" >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages. Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting.
                </p>

                <p className="text-left">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages. Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic.
                </p>
             
                    
                <div className="inner_blog">
                <Row>
                  <Col md={7}>
                    <div className="blog_img_two">
                      <img
                        src={targetItem.imgURL1}
                        alt={`img-${targetItem.id}`}
                      />
                    </div>
                  </Col>

                  <Col md={5}>
                    <p className="text-left">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages. Lorem Ipsum is simply dummy text of the
                      printing and typesetting industry. Lorem Ipsum has been
                      the industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled.
                    </p>

                    <p className="text-left">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised.
                    </p>
                  </Col>
                </Row>
                </div>

                <p className="text-left">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised.
                </p>
              </div>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  } else {
    return <p>Item not found</p>
  }
}
export default BlogOne
